<template>
  <div class="cart-item-container" v-bind:class="rootClassName">
    <div class="cart-item-cartrow">
      <div class="cart-item-cartimage">
        <img :src="firstImage(item)" :alt="image_alt" class="cart-item-image" />
      </div>
      <div class="cart-item-cartdetail">
        <div class="cart-item-cartdetailheader">
          <div class="cart-item-leftdetail">
            <span class="cart-item-text">{{ item.title }}</span>
            <span class="cart-item-text01">{{ formatIDR(item.price) }}</span>
          </div>
          <div
            class="cart-item-imagegroup cursor-pointer"
            @click="deleteCart(item.cart_id)"
          >
            <img
              :src="image_src1"
              :alt="image_alt1"
              class="cart-item-trashmage"
            />
            <img :src="image_src2" :alt="image_alt2" class="cart-item-image1" />
          </div>
        </div>
        <div class="cart-item-tabelcart">
          <div class="cart-item-columncart" v-for="pv in variants" :key="pv.id">
            <span class="cart-item-text02">{{ pv.title }}</span>
            <span>{{ pv.name }}</span>
          </div>
          <!-- <div class="cart-item-columncart1">
            <span class="cart-item-text04">{{ armpad }}</span>
            <span>{{ armpad_value }}</span>
          </div>
          <div class="cart-item-columncart2">
            <span class="cart-item-text06">{{ size }}</span>
            <span>{{ size_value }}</span>
          </div>
          <div class="cart-item-columncart3">
            <span class="cart-item-text08">{{ size1 }}</span>
            <span>{{ size_value1 }}</span>
          </div> -->

          <div class="cart-item-columncart4">
            <span class="cart-item-text10">{{ qty }}</span>
            <span>{{ item.qty }}</span>
          </div>
          <template v-if="item.is_dp > 0">
          <div class="cart-item-columncart5">
            <span class="cart-item-text12">Total Price</span>
            <span class="cart-item-text13">{{ formatIDR(item.price * item.qty) }}</span>
          </div>
          <div class="cart-item-columncart5">
            <span class="cart-item-text12">{{ total }} DP</span>
            <span class="cart-item-text13">{{ formatIDR(item.total) }}</span>
          </div>
          </template>
          <template v-else>
          <div class="cart-item-columncart5">
            <span class="cart-item-text12">{{ total }}</span>
            <span class="cart-item-text13">{{ formatIDR(item.total) }}</span>
          </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import Api from '../api';
  import { formatRupiah } from '../lib/index';
  export default {
    name: 'CartItem',
    computed: {
      variants() {
        return this.item.product_variants.reverse();
      },
    },
    methods: {
      firstImage(item) {
        let text = item?.image
        const myArray = text.split(",");
        return item?.image
          ? myArray[0]
          : 'https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png';
      },
      formatIDR(rp) {
        return 'IDR ' + formatRupiah(rp);
      },
      async deleteCart(id) {
        await axios.delete(`${Api.cartUrl}/${id}`);
        await this.$emit('on:deleted');
      },
    },
    props: {
      image_src: {
        type: String,
        default: '/playground_assets/placeholder.jpeg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      text: {
        type: String,
        default: 'Eames Lounge Chairs & Ottoman',
      },
      text1: {
        type: String,
        default: 'Rp 1.390.000',
      },
      image_src1: {
        type: String,
        default: '/playground_assets/trash.svg',
      },
      image_alt1: {
        type: String,
        default: 'image',
      },
      color: {
        type: String,
        default: 'Color',
      },
      color_value: {
        type: String,
        default: 'Black',
      },
      armpad: {
        type: String,
        default: 'Armpad',
      },
      armpad_value: {
        type: String,
        default: 'Black',
      },
      size: {
        type: String,
        default: 'Aeron Size',
      },
      size_value: {
        type: String,
        default: 'C',
      },
      size1: {
        type: String,
        default: 'Aeron Caster BB',
      },
      size_value1: {
        type: String,
        default: 'BB',
      },
      qty: {
        type: String,
        default: 'Qty',
      },
      qty_value: {
        type: String,
        default: '1',
      },
      total: {
        type: String,
        default: 'Total',
      },
      total_value: {
        type: String,
        default: 'Rp 1.390.000',
      },
      rootClassName: String,
      image_src2: {
        type: String,
        default: '/playground_assets/ic_close.svg',
      },
      image_alt2: {
        type: String,
        default: 'image',
      },
      item: Object,
    },
  };
</script>

<style scoped>
  .cart-item-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .cart-item-cartrow {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #eae3d9;
    padding-bottom: var(--dl-space-space-unit);
  }
  .cart-item-cartimage {
    flex: 0 0 auto;
    width: var(--dl-size-size-large);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .cart-item-image {
    width: 100px;
    object-fit: cover;
  }
  .cart-item-cartdetail {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .cart-item-cartdetailheader {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .cart-item-leftdetail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .cart-item-text {
    color: #1f252c;
    font-size: 18px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .cart-item-text01 {
    color: #1f252c;
    font-style: normal;
    margin-top: 8px;
    font-family: Montserrat;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .cart-item-imagegroup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .cart-item-trashmage {
    width: 18px;
    object-fit: cover;
  }
  .cart-item-image1 {
    width: 24px;
    display: none;
    object-fit: cover;
  }
  .cart-item-tabelcart {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-right: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .cart-item-columncart {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .cart-item-text02 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .cart-item-columncart1 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .cart-item-text04 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .cart-item-columncart2 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .cart-item-text06 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .cart-item-columncart3 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .cart-item-text08 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .cart-item-columncart4 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .cart-item-text10 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .cart-item-columncart5 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .cart-item-text12 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .rootClassName {
    margin-bottom: var(--dl-space-space-unit);
  }
  .rootClassName1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .rootClassName2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  @media (max-width: 1089px) {
    .cart-item-trashmage {
      display: none;
    }
    .cart-item-image1 {
      display: flex;
    }
    .cart-item-tabelcart {
      flex-wrap: wrap;
      justify-content: space-between;
      padding-right: 0;
    }
    .cart-item-columncart {
      margin-right: 0px;
    }
    .cart-item-columncart1 {
      width: 33%;
      margin-right: 0px;
    }
    .cart-item-columncart2 {
      width: 33%;
      margin-right: 0px;
    }
    .cart-item-columncart3 {
      width: 30%;
      margin-top: var(--dl-space-space-unit);
      margin-right: 0px;
    }
    .cart-item-columncart4 {
      width: 60%;
      /* margin-top: var(--dl-space-space-unit); */
      margin-right: 0px;
    }
    .cart-item-columncart5 {
      width: 100%;
      margin-top: var(--dl-space-space-unit);
      flex-direction: row;
      justify-content: space-between;
    }
    .cart-item-text13 {
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
    }
  }
</style>

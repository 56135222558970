<template>
  <div class="relative cart-container">
    <div class="w-full max-w-rifyo mx-auto">
      <div class="relative z-10 cart-cartrows">
        <div class="cart-cartrowsitemleft max-xl:px-5">
          <div class="cart-leftheader">
            <h1 class="cart-leftheaderheading">Cart</h1>
            <span v-if="!isFetching" class="cart-leftheaderitems mb-2"
              >{{ cartCount }} {{ cartCount > 1 ? 'Items' : 'Item' }}</span
            >
            <span v-else class="cart-leftheaderitems mb-2">Loading...</span>
          </div>
          <div v-if="isFetching" class="py-5">
            <Loading />
          </div>
          <template v-else>
            <template v-if="carts.product.length">
              <span class="cart-headerproduct">Product</span>
              <cart-item
                v-for="item in carts.product"
                :key="item.id"
                :item="item"
                rootClassName="rootClassName"
                @on:deleted="getCart"
              />
            </template>
            <template v-if="carts.product_indent.length">
              <span class="cart-headerproduct1">Indent Product</span>
              <cart-item
                v-for="item in carts.product_indent"
                :key="item.id"
                :item="item"
                rootClassName="rootClassName"
                @on:deleted="getCart"
              />
            </template>
            <div
              class="w-full pt-16 flex flex-col align-center justify-center"
              v-if="!carts.product.length && !carts.product_indent.length"
            >
              <img
                class="mb-8 w-16 mx-auto"
                src="/playground_assets/shopping-cart.svg"
                alt="Shopping Cart"
              />
              <div class="mb-4 empty-cart-h1">
                You don’t have any products in your cart.
              </div>
              <div class="mb-8 empty-cart-h4">
                You haven’t added anything to your cart.
              </div>
              <div class="text-center">
                <button
                  class="px-8 py-2 empty-cart-btn"
                  @click="$router.push('/shop/all')"
                  style="background-color: #1f252c; color: white"
                >
                  Browse Products
                </button>
              </div>
            </div>
          </template>
        </div>
        <div class="cart-cartrowsitemright max-xl:px-5">
          <div class="cart-summary">
            <span class="cart-headerproduct2 font-bold">Summary</span>
          </div>
          <div class="cart-subtotal">
            <span class="cart-subtotallabel">Sub Total</span>
            <span class="cart-subtotalvalue">{{
              formatIDR(carts.sub_total)
            }}</span>
          </div>
          <div class="cart-grosstotal">
            <span class="cart-grosstotallabel">Total</span>
            <span class="cart-grosstotalvalue">{{ formatIDR(carts.total) }}</span>
          </div>
          <div
            class="cart-btn-login"
            :class="cartCount > 0 ? '' : 'opacity-60 cursor-default'"
            @click="cartCount > 0 ? goPage('/checkout') : ''"
          >
            <span class="cart-text">Checkout</span>
          </div>
        </div>
      </div>
      <div class="bgelement absolute bg-[#eae3d9] h-full w-[35%] xl:w-[38%] 2xl:w-[41%] right-0 top-0">
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import CartItem from '../components/cart-item';
  import Footer from '../components/footer';
  import { formatRupiah } from '../lib/index';
  import Loading from '../components/Loading.vue';
  export default {
    name: 'Cart',
    props: {},
    data() {
      return {
        isFetching: false,
      };
    },
    components: {
      CartItem,
      Footer,
      Loading,
    },
    computed: {
      cartCount() {
        return this.$store.state.cartCount.count;
      },
      carts() {
        return this.$store.state.cartCount.carts;
      },
    },
    async created() {
      await this.getCart();
    },
    methods: {
      async getCart() {
        try {
          this.isFetching = true;
          this.$store.dispatch('getCart');
          // const cart = await axios.get(Api.cartUrl);
          // this.carts = cart.data.data;
          // const cartLength = cart.data.total_qty;
          // this.$store.commit('setCount', cartLength);
        } catch (error) {
          throw error;
        } finally {
          this.isFetching = false;
        }
      },
      goPage(page) {
        this.$router.push(page);
      },
      formatIDR(rp) {
        return 'IDR ' + formatRupiah(rp);
      },
      onDeleted() {
        const cartLength =
          this.carts.product.length + this.carts.product_indent.length;
        if (cartLength > 0) {
          const inc = cartLength - 1;
          this.$store.commit('setCount', inc);
        } else {
          this.$store.commit('setCount', 0);
        }
      },
    },
  };
</script>

<style scoped>
  .empty-cart-h1 {
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
  }
  .empty-cart-h4 {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .empty-cart-btn {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.24em;
    text-align: left;
  }
  .cart-container {
    width: 100%;
    display: flex;
    overflow: auto;
    height: 100%;
    align-items: center;
    flex-direction: column;
  }
  .cart-cartrows {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
  }
  .cart-cartrowsitemleft {
    flex: 0 0 auto;
    width: 65%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 120px;
    padding-right: 40px;
    flex-direction: column;
  }
  .cart-leftheader {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .cart-leftheaderheading {
    font-family: Lora;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
  }
  .cart-leftheaderitems {
    color: #575f6e;
    font-family: Montserrat;
    margin-left: 16px;
  }
  .cart-headerproduct {
    color: #1f252c;
    font-size: 24px;
    font-style: normal;
    margin-top: 24px;
    font-family: Lora;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .cart-headerproduct1 {
    color: #1f252c;
    font-size: 24px;
    font-style: normal;
    margin-top: 24px;
    font-family: Lora;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .cart-cartrowsitemright {
    flex: 0 0 auto;
    width: 35%;
    /* height: 100%; */
    display: flex;
    align-items: flex-start;
    padding-top: 120px;
    padding-left: 40px;
    flex-direction: column;
  }
  .cart-summary {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #aea79d;
    flex-direction: column;
  }
  .cart-headerproduct2 {
    color: #1f252c;
    font-size: 24px;
    font-style: normal;
    margin-top: 24px;
    font-family: Lora;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .cart-subtotal {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .cart-subtotallabel {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .cart-subtotalvalue {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .cart-grosstotal {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .cart-grosstotallabel {
    color: #1f252c;
    font-size: 18px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .cart-grosstotalvalue {
    color: #1f252c;
    font-size: 18px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .cart-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 44px;
    display: flex;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
    background-color: #1f252c;
  }
  .cart-text {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.4px;
  }
  @media (max-width: 991px) {
    .bgelement {
      display: none;
    }
    .cart-cartrows {
      height: auto;
      flex-wrap: wrap;
    }
    .cart-cartrowsitemleft {
      width: 100%;
    }
    .cart-cartrowsitemright {
      width: 100%;
      padding: 40px 0;
      background-color: #eae3d9;
    }
    .cart-summary {
      display: none;
    }
    .cart-subtotal {
      display: none;
    }
  }
  @media (min-width: 2560px) {
    .bgelement {
      width: 44vw !important;
    }
  }
</style>
